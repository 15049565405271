import AddEventDetails from "./add-event-details"
import useTabs from "hooks/useTabs"
import RtTabs from "components/tabs"
import AddTicketGroup from "./add-ticket-group"
import { useState } from "react"
import { useHostEvent } from "hooks/api/useHostEvent"
import { formatHostEventFormData } from "./utils"
import { HostEventFormDataType } from "types/form-data.types"
import { useNavigate, useSearchParams } from "react-router-dom"
import {
  isKycAPendingOrApproved,
  isUserEmailVerified,
  useGetLoggedInUserType,
} from "hooks/useUser"
import KycAlertModal from "components/modal/KycAlertModal"
import useDisclosure from "hooks/useDisclosure"
import { useGetLoggedInUser } from "hooks/api/useGetLoggedInUser"
import { REGULAR } from "constants/global.constants"
import { handLogInUser } from "redux/features/user"
import { useDispatch } from "react-redux"
import EmailVerificationPrompt from "components/modal/EmailVerificationPrompt"
import { createUserType } from "utils/create-user-type"

type TabLabels = "Create event" | "Add ticket"

interface TabContent {
  label: TabLabels
  content: React.ReactNode
  isHidden?: boolean
}

function HostEvent() {
  const dispatch = useDispatch()
  const [URLSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const isKycSetup = isKycAPendingOrApproved()
  const isUserVerified = isUserEmailVerified()
  const { isOpen: isKycModalOpen, onClose: onCloseKycModal } = useDisclosure(
    !isKycSetup,
  )

  const { isOpen: isEmailVerificationPromptOpen } = useDisclosure(
    !isUserVerified,
  )

  const [eventData, setEventData] = useState<HostEventFormDataType>({
    name: "",
    description: "",
    start_date: null,
    end_date: null,
    category: undefined,
    place: undefined,
    image: null,
    ticket_groups: [
      {
        name: "",
        price: "",
        start_date: null,
        end_date: null,
        seats: "",
      },
    ],
  })

  const initialTab = (URLSearchParams.get("tab") as TabLabels) ?? "Create event"
  const { activeTab, changeTab } = useTabs<TabLabels>({
    initialTab,
    queryKey: "tab",
  })

  // handle input change
  const handleInputChange = (value: { [key: string]: any }) => {
    setEventData((prev) => ({ ...prev, ...value }))
  }

  // handle ticket group input change
  const handleTicketGroupInputChange = (
    value: { [key: string]: any },
    idx: number,
  ) => {
    setEventData((prev) => ({
      ...prev,
      ticket_groups: prev.ticket_groups.map((ticketGroup, index) => {
        if (idx === index) {
          return { ...ticketGroup, ...value }
        }

        return ticketGroup
      }),
    }))
  }

  //add ticket group to the form data
  const handleAddTicketGroup = () => {
    setEventData((prev) => ({
      ...prev,
      ticket_groups: [
        ...prev.ticket_groups,
        {
          name: "",
          price: "",
          start_date: null,
          end_date: null,
          seats: "",
        },
      ],
    }))
  }

  // remove ticket group from the form data
  const handleRemoveTicketGroup = (idx: number) => {
    setEventData((prev) => ({
      ...prev,
      ticket_groups: prev.ticket_groups.filter((_, index) => idx !== index),
    }))
  }

  // get host event query client
  const hostEventQueryClient = useHostEvent()

  const handleCreateEvent = async () => {
    const formData = formatHostEventFormData(eventData)

    hostEventQueryClient.mutate(formData)
  }
  //refetch logged user details if its their first time hosting an event
  const { data, isSuccess: isGetLoggedUserSuccess } = useGetLoggedInUser(
    hostEventQueryClient.isSuccess && useGetLoggedInUserType() === REGULAR,
  )

  if (isGetLoggedUserSuccess) {
    const user_type = createUserType(data.data)

    dispatch(handLogInUser({ ...data.data, user_type }))
  }

  // tabs
  const tabs: TabContent[] = [
    {
      label: "Create event",
      content: (
        <AddEventDetails
          handleNextTab={() => changeTab("Add ticket")}
          formData={eventData}
          handleChange={handleInputChange}
        />
      ),
    },
    {
      label: "Add ticket",
      content: (
        <AddTicketGroup
          handleCreateEvent={handleCreateEvent}
          ticketGroups={eventData.ticket_groups}
          isEventPublishing={hostEventQueryClient.isPending}
          handleChange={handleTicketGroupInputChange}
          handleAddTicketGroup={handleAddTicketGroup}
          handleRemoveTicketGroup={handleRemoveTicketGroup}
          eventDates={{
            start_date: eventData.start_date,
            end_date: eventData.end_date,
          }}
        />
      ),
    },
  ]

  // get the current tab content
  const getCurrentTabContent = (currentTab_: TabLabels) => {
    return tabs.find((tab) => tab.label === currentTab_)?.content
  }

  return (
    <div>
      <RtTabs<TabLabels>
        tabs={["Create event", "Add ticket"]}
        disabledTabs={isKycSetup ? [] : ["Create event", "Add ticket"]}
        initialTab="Create event"
      />

      <div>{getCurrentTabContent(activeTab)}</div>

      <KycAlertModal isOpen={isKycModalOpen} onClose={onCloseKycModal} />
      <EmailVerificationPrompt
        isOpen={isEmailVerificationPromptOpen}
        onClose={() => navigate("/")}
      />
    </div>
  )
}

export default HostEvent
