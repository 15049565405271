import { NETWORK_ERROR_CODE, SUPPORT_EMAIL } from "constants/global.constants"
import API from "./api.util"
import { showToaster } from "./show-toaster.util"

export const handleGetRequest = async (url: string, params?: any) => {
  try {
    const response = await API.get(url, { params })

    return response.data
  } catch (error) {
    handleError(error)
  }
}

export const handlePostRequest = async (url: string, data: any) => {
  try {
    const response = await API.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })

    return response
  } catch (error) {
    handleError(error)

    throw error
  }
}

export const handleDeleteRequest = async (url: string) => {
  try {
    const response = await API.delete(url, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })

    return response
  } catch (error) {
    throw error
  }
}

export function handleApiSuccess(response: any) {
  const message = response.data.success_message

  if (message) {
    showToaster("success", message)
  }
}

export function handleError(err: any) {
  // handle network error
  if (err.code === NETWORK_ERROR_CODE) {
    showToaster(
      "error",
      "You are offline",
      3000,
      "top-center",
      NETWORK_ERROR_CODE, //NOTE: Id ensures a toaster is only thrown once
    )

    return
  }

  const { response } = err

  // handle server error

  // handle 404 error
  if (response.status === 404) {
    showToaster("error", "Sorry the requested resource does not exist")

    return response
  }

  if (response.status >= 500) {
    //TODO:report 500 errors to sentry-> Masho Ado 24/10/2023

    showToaster(
      "error",
      `We're experiencing a temporary hiccup. Please try again in 5 minutes. If the challenge persists, contact our dedicated support team at ${SUPPORT_EMAIL}`,
    )

    return
  }

  // handle api errors with error message
  if (response?.data?.error_message) {
    showToaster(
      "error",
      response?.data?.error_message,
      5000,
      undefined,
      response?.data?.error_message,
    )
    return
  }
}
